<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-2" height="48px">
        <v-btn icon href="/#/types/"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-toolbar-title >Fiche {{form.typ_id}} : {{form.typ_name}}</v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Nom" v-model="form.typ_name"></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Identifiant type"    v-model="form.typ_id" :disabled="modify" ></v-text-field>
            <v-text-field label="Entité"  v-model="form.typ_entity"></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select :items="visibility" item-value="value" v-model="form.typ_visibility" label="Statut" single-line>
            </v-select>
            <v-flex xs6 class="pa-2">
                <v-menu offset-y content-class="elevation-0" style="z-index:900">
                    <template v-slot:activator="{ on }">
                        <v-btn :color="form.typ_color" block dark class="mt-3" v-on="on">Couleur</v-btn>
                    </template>
                    <v-color-picker v-model="form.typ_color" class="ma-2 elevation-0" hide-canvas :swatches="swatches" show-swatches></v-color-picker>
                </v-menu>
            </v-flex>
            </v-flex>
        </v-layout>
        <div class="text-right">
            <v-btn color="success" :loading="saveProgress" @click.native="saveForm()" :disabled="saveProgress">
            Enregistrer
            </v-btn>
            <v-btn color="primmary" href="/#/types/">
            Retour
            </v-btn>
        </div>
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheMutations } from "../store/cache.module"

export default {
    name: 'typesedit',
    props: ['toast'],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            visibility: [
                {'value': 1,    'text': 'Visible'},
                {'value': 0,    'text': 'Désactivé'}
            ],
            modify: false,
            form:{
              typ_id:'', typ_tag: '', typ_entity: '',
              typ_name: '', typ_visibility: 1, typ_color: '', 
              typ_created_at: '', typ_updated_at: '',
            },
            locDateFormat: dateLocale, // Format de date
            swatches: [
                ['#FF0000', '#AA0000', '#550000'], ['#FFFF00', '#AAAA00', '#555500'], ['#00FF00', '#00AA00', '#005500'],
                ['#00FFFF', '#00AAAA', '#005555'], ['#0000FF', '#0000AA', '#000055'],
            ],
        }
    },
    computed: {
        progress () {
            return Math.min(100, this.form.usr_password.length * 12.5)
        },
        color () {
            return ['error', 'warning', 'success'][Math.floor(this.progress / 50)]
        }
    },
    mounted:function (){
        if( ( this.$route.params.typ_id ) && this.$route.params.typ_id.length > 3 ){
            this.modify = true
            this.$http.get( '/types/' + this.$route.params.typ_id )
            .then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( this.form[prop] !== undefined ){
                        this.form[prop] = response.data.data[prop] 
                    }
                }
            })
            .catch( (error) => {
                this.toast({ color: 'warning', text: error })
            })
        }
    },
    methods: {
        saveForm () {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.$route.params.typ_id.length > 3 ){
                this.$http.put( '/types/' + this.$route.params.typ_id, this.form )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.getTypes()
                    this.$router.push({ path: '/types' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                })
            } else {
                this.$http.post( '/types/', this.form )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/types' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                })
            }
        },
        getTypes () {
        return new Promise( (resolve, reject) => {
            this.$http.get( '/types?per_page=false' ).then( (response) => {
            let types = {}
            response.data.data.forEach( (item) => {
                if( types[ item.typ_id.substring(0, 3) ] == undefined ){
                types[ item.typ_id.substring(0, 3) ] = {}
                }
                types[ item.typ_id.substring(0, 3)][item.typ_id] = item
            })
            cacheMutations.setTypes( types )
            resolve('resolved')
            }).catch( () => { 
            reject('failed')
            })
        })
        },
    },
    components: { }
}
</script>