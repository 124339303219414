<template>
<v-container fluid class="pa-0">
  <v-toolbar class="elevation-1" color="barrinfo">
      <v-toolbar-title >Mon Planning</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
        Aujourd'hui
      </v-btn>
      <v-btn fab text small color="grey darken-2" @click="prev">
        <v-icon small>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-btn fab text small color="grey darken-2" @click="next">
        <v-icon small>
          mdi-chevron-right
        </v-icon>
      </v-btn>
      <v-toolbar-title v-if="$refs.calendar">
        {{ $refs.calendar.title }}
      </v-toolbar-title>
  </v-toolbar>
  <v-card class="mt-7 ma-4 mb-2">
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="600">
        <v-calendar ref="calendar" v-model="focus" first-time="08:00" color="primary" type="category" category-show-all :categories="categories" 
                    :events="events" :event-color="getEventColor" @change="fetchEvents">
        </v-calendar>
      </v-sheet>
    </v-col>
  </v-row>
  </v-card>
</v-container>
</template>

<script>
  export default {
    data: () => ({
      focus: '',
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Curatif', 'PTO', 'Deplacement', 'Evenement', 'Preventif', 'Conference', 'Absence'],
      categories: ['John Smith', 'Pierre Walker'],
    }),
    mounted () {
      this.$refs.calendar.checkChange()
    },
    methods: {
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      fetchEvents ({ start, end }) {
        const events = []

        const min = new Date(`${start.date}T07:00:00`)
        const max = new Date(`${end.date}T19:00:00`)
        const days = (max.getTime() - min.getTime()) / 86400000
        const eventCount = this.rnd(days, days + 20)

        for (let i = 0; i < eventCount; i++) {
          const allDay = this.rnd(0, 3) === 0
          const firstTimestamp = this.rnd(min.getTime(), max.getTime())
          const first = new Date(firstTimestamp - (firstTimestamp % 900000))
          const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
          const second = new Date(first.getTime() + secondTimestamp)

          events.push({
            name: this.names[this.rnd(0, this.names.length - 1)],
            start: first,
            end: second,
            color: this.colors[this.rnd(0, this.colors.length - 1)],
            timed: !allDay,
            category: this.categories[this.rnd(0, this.categories.length - 1)],
          })
        }

        this.events = events
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
    },
  }
</script>