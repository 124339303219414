/* *****************************
    Objet : Tracking sheet
******************************** */
import { cacheGetters } from "../store/cache.module"

export const trsObj = {
    data: function () {
        return {
            objTrackingSheet: {
                trs_id: 0, trs_sit_id: 0, trs_typ_id:'SIT06', trs_sta_id : 'GEN01', trs_adr_id : null,
                trs_tow_id_link: null, trs_wam_id: null, trs_mileage_point: '', trs_rail_distance:'',
                trs_coddt: '', trs_name: '', trs_area_plot: 0, trs_area_specification:0,
                trs_date:'', trs_label: '', trs_mc2_code: '',
                _table: {},
                _data: {
                    trackingstates: {}, contacts: {}, 
                    trackingplot: [], diagnostic: [], species: [], 
                    water : [], initimpact : [], habitat : [], event : [], habitatdetdet : []
                },
                _info: { town: {} }
            },
            objTrackingSheetClone: {},
            listTrackingSheet: [],
            metaTrackingSheet: {},
            bookmark: cacheGetters.getBookmark()
        }
    },
    computed: {
        objTrackingSheetInfo(){
            let type = cacheGetters.getTypes( 'SIT', this.objTrackingSheet.trs_typ_id )
            return {
                typeContract: type.typ_tag
            }
        }
    },
    beforeMount: function (){
        this.objTrackingSheetClone = JSON.stringify( this.objTrackingSheet )
        this.objTrackingSheet._data['trackingstates'] = {}
        let state = cacheGetters.getTypes('TST')
        var tstObj= {}
        state.forEach(type => {
            //this.objTrackingSheet._data['trackingstates'][type.typ_id] = {}
            tstObj[type.typ_id] = {
                'tst_trs_id'        : this.objTrackingSheet.trs_id,
                'tst_typ_id'        : type.typ_id, 'tst_sta_id' : 'GEN01',
                'tst_state_numeric' : '', 'tst_date'      : undefined, 'tst_value'      : '', 'tst_value_two': '', 'tst_note': {},
                'tst_created_at'    : '', 'tst_updated_at': '', 'tst_deleted_at': ''
            }
        })
        this.objTrackingSheet._data['trackingstates'] = Object.assign({}, this.objTrackingSheet._data['trackingstates'], tstObj)

        state = cacheGetters.getTypes('TSC')
        state.forEach(type => {
            if( this.objTrackingSheet._data['contacts'][type.typ_id] == undefined ){
                this.objTrackingSheet._data['contacts'][type.typ_id] = [
                    { 
                        ctt_firstname: "", ctt_lastname: "", ctt_middlename: "", ctr_created_at: "", ctr_ctt_id: null,
                        ctr_deleted_at: "", ctr_sta_id: "GEN01", ctr_trs_id: null, ctr_typ_id: type.typ_id
                    }
                ]
            }
        });
    },
    methods: {
        getTrackingSheetFilter(page, perPage){
            page    = page || 1
            perPage = perPage || 'false'
            let filter = cacheGetters.getFilter()
            var searchCriteria = '?page='+ page +'&per_page=' + perPage + '&extended=true'
            for( var prop in filter ){
                if( filter[prop] !== undefined && filter[prop].length > 0 ){
                    searchCriteria += '&' + prop + '=' + filter[prop]
                }
            }
            return searchCriteria
        },
        getTrackingSheetShow(options){
            return new Promise( (resolve, reject) => {
                options = options || ''
                if( options.length > 0 ){
                    options = '/?include=' + options
                }
                this.$http.get( '/trackingsheets/' + this.objTrackingSheet.trs_id + options).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( typeof response.data.data[prop] === 'object' && response.data.data[prop] !== null && response.data.data[prop]['data'] !== undefined ){
                            this.objTrackingSheet._info[prop] = response.data.data[prop]['data']
                        } else {
                            this.objTrackingSheet[prop] = response.data.data[prop]
                        }
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getTrackingSheetIndex(page, perPage, order){
            return new Promise( (resolve, reject) => {
                order = order || ''
                this.listTrackingSheet = []
                let searchCriteria = this.getTrackingSheetFilter(page, perPage)

                this.$http.get( '/trackingsheets/' + searchCriteria + order ).then( (response) => {
                    this.metaTrackingSheet = response.data.meta
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.listTrackingSheet = response.data.data
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        postTrackingSheetStore(){
            return new Promise( (resolve, reject) => {
                this.$http.post( '/trackingsheets', this.objTrackingSheet ).then( (response) => {
                    this.objTrackingSheet.trs_id = response.data.data.trs_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        putTrackingSheetStore(){
            return new Promise( (resolve, reject) => {
                this.$http.put( '/trackingsheets/' + this.objTrackingSheet.trs_id, this.objTrackingSheet ).then( (response) => {
                    this.objTrackingSheet.trs_id = response.data.data.trs_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        deleteTrackingSheetDestroy(){
            return new Promise( (resolve, reject) => {
                this.$http.delete( '/trackingsheets/' + this.objTrackingSheet.trs_id ).then( (response) => {
                    this.objTrackingSheet.trs_id = response.data.trs_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getTrackingStateIndex(){
            return new Promise( (resolve, reject) => {
                this.$http.get( '/trackingsheets/' + this.objTrackingSheet.trs_id + '/trackingstates' ).then( (response) => {
                    response.data.data.forEach(typeState => {
                        if( this.objTrackingSheet._data['trackingstates'][typeState.tst_typ_id] !== undefined ){
                            try {
                                typeState['tst_note'] = JSON.parse(typeState['tst_note'])
                                typeState['tst_note'] = ( typeState['tst_note'] === null ) ? {} : typeState['tst_note']
                            } catch (e) {
                                typeState['tst_note'] = {}
                            }
                            this.objTrackingSheet._data['trackingstates'][typeState.tst_typ_id] = Object.assign({}, this.objTrackingSheet._data['trackingstates'][typeState.tst_typ_id], typeState)
                        }
                        //this.objTrackingSheet._data['trackingstates'][typeState.tst_typ_id] = typeState
                    })
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getTrackingContactIndex(){
            return new Promise( (resolve, reject) => {
                this.$http.get( '/trackingsheets/' + this.objTrackingSheet.trs_id + '/contacts' ).then( (response) => {
                    response.data.data.forEach(typeState => {
                        if( this.objTrackingSheet._data['contacts'][typeState.ctr_typ_id][0].ctr_ctt_id === null ){
                            this.objTrackingSheet._data['contacts'][typeState.ctr_typ_id] = []
                        }
                        this.objTrackingSheet._data['contacts'][typeState.ctr_typ_id].push(typeState)
                    })
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getTrackingZoningIndex(){
            return new Promise( (resolve, reject) => {
                this.$http.get( '/trackingsheets/' + this.objTrackingSheet.trs_id + '/zoning' ).then( (response) => {
                    this.objTrackingSheet._data['trackingzone'] = response.data
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        cloneTrackingSheetObj(){
            this.objTrackingSheetClone = JSON.stringify( this.objTrackingSheet )
        },
        cleanTrackingSheetObj(){
            var trackingSheetClone = JSON.parse( this.objTrackingSheetClone )
            var prop
            for( prop in trackingSheetClone ){
                if( this.objTrackingSheet[prop] !== undefined ){
                    this.objTrackingSheet[prop] = trackingSheetClone[prop]
                }
            }
            //this.trackingSheetClone = Object.assign({}, this.objTrackingSheet)
        },
        deleteContactCollection(deleteContact){
            return new Promise( (resolve, reject) => {
                if( deleteContact.length ){
                    this.$http.post( '/trackingsheets/' + this.objTrackingSheet.trs_id + '/contacts/deletes', { table:"trs", contacts: deleteContact } )
                    .then( () => {
                        resolve('resolved')
                    }).catch( (error) => { 
                        reject(error)
                    })
                } else {
                    resolve('resolved')
                }
            })
        },
        insertContactCollection(addContact){
            return new Promise( (resolve, reject) => {
                if( addContact.length ){
                    this.$http.post( '/trackingsheets/' + this.objTrackingSheet.trs_id + '/contacts/inserts', { table:"trs", contacts: addContact } )
                    .then( () => {
                        resolve('resolved')
                    }).catch( (error) => { 
                        reject(error)
                    })
                } else {
                    resolve('resolved')
                }
            })
        },
        insertStateCollection(stateColletion){
            return new Promise( (resolve, reject) => {
                if( stateColletion.length ){
                    this.$http.post( '/trackingsheets/' + this.objTrackingSheet.trs_id + '/trackingstates/inserts', { trackingstates: stateColletion } )
                    .then( () => {
                        resolve('resolved')
                    }).catch( (error) => { 
                        reject(error)
                    })
                } else {
                    resolve('resolved')
                }
            })
        },
        async saveTrackingSheet(){
            var trackingSheetClone = JSON.parse( this.objTrackingSheetClone )
            let currentTrs = []
            let oldTrs     = []
            for( let prop in this.objTrackingSheet._data['contacts'] ) {
                this.objTrackingSheet._data['contacts'][prop].forEach( (elem) => {
                    if( elem.ctr_ctt_id !== undefined && elem.ctr_ctt_id !== null ){
                        currentTrs.push( elem.ctr_typ_id + ':' + elem.ctr_ctt_id )
                    }
                })
            }
            for( let prop in trackingSheetClone._data['contacts'] ) {
                trackingSheetClone._data['contacts'][prop].forEach( (elem) => {
                    if( elem.ctr_ctt_id !== undefined && elem.ctr_ctt_id !== null ){
                        oldTrs.push( elem.ctr_typ_id + ':' + elem.ctr_ctt_id )
                    }
                })
            }
            var deleteContact = oldTrs.filter(x => currentTrs.indexOf(x) === -1 ) // contacts to delete
            var addContact    = currentTrs.filter(x => oldTrs.indexOf(x) === -1 ) // contacts to insert
            
            // Diff states
            var stateColletion = []
            for( let prop in this.objTrackingSheet._data['trackingstates'] ) {
                let currentObj = this.objTrackingSheet._data['trackingstates'][prop]
                let OldObj     = trackingSheetClone._data['trackingstates'][prop]
                let isEqual    = true
                for( let keyTyp in currentObj ) {
                    if( typeof currentObj[keyTyp] === 'object' && currentObj[keyTyp] !== null ){
                        for( let keySub in currentObj[keyTyp] ) {
                            if ( OldObj[keyTyp][keySub] !== currentObj[keyTyp][keySub]  ){
                                isEqual = false
                            }
                        }
                    } else if ( OldObj[keyTyp] !== currentObj[keyTyp]  ){
                        isEqual = false
                    }
                }
                if( !isEqual ){
                    stateColletion.push( this.objTrackingSheet._data['trackingstates'][prop] )
                }
            }
            await this.putTrackingSheetStore()
            await this.deleteContactCollection(deleteContact)
            await this.insertContactCollection(addContact)
            await this.insertStateCollection(stateColletion)

        }
    }
}