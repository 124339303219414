<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-2" height="48px">
        <v-btn icon href="/#/contacts/"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-toolbar-title >Fiche contact</v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Nom"    v-model="form.ctt_lastname" ></v-text-field>
            <v-text-field label="Tel" v-model="form.ctt_phone"></v-text-field>
            <v-text-field label="Nom complet / Autre"  v-model="form.ctt_middlename"></v-text-field>
            <v-select 
                item-text="sta_label" item-value="sta_id"
                :items="getCacheState('GEN')" v-model="form.ctt_sta_id" label="Statut" single-line >
            </v-select>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Prénom" v-model="form.ctt_firstname"></v-text-field>
            <v-text-field label="Mobile" v-model="form.ctt_mobile"></v-text-field>
            <v-text-field label="Courriel" v-model="form.ctt_email"></v-text-field>
            <v-select 
                item-text="typ_name" item-value="typ_id"
                :items="getCacheType('ACT')" v-model="form.ctt_typ_id" label="Type" single-line >
            </v-select>
        </v-flex>
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header>Société / Coordonnées bancaire</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-layout row wrap>
                        <v-flex xs12 class="pa-2">
                            <v-text-field label="Dénomination sociale" v-model="form.ctt_company_name"></v-text-field>
                        </v-flex>
                        <v-flex xs4 class="pa-2">
                            <v-text-field label="NAF" v-model="form.ctt_customfields['ctt_naf']"></v-text-field>
                        </v-flex>
                        <v-flex xs4 class="pa-2">
                            <v-text-field label="SIRET" v-model="form.ctt_customfields['ctt_siret']"></v-text-field>
                        </v-flex>
                        <v-flex xs4 class="pa-2">
                            <v-text-field label="TVA Intra" v-model="form.ctt_customfields['ctt_tva']"></v-text-field>
                        </v-flex>
                        <v-flex xs4 class="pa-2">
                            <v-text-field label="RIB" v-model="form.ctt_customfields['ctt_bnk_rib']"></v-text-field>
                        </v-flex>
                        <v-flex xs4 class="pa-2">
                            <v-text-field label="Domiciliation" v-model="form.ctt_customfields['ctt_bnk_dom']"></v-text-field>
                        </v-flex>
                        <v-flex xs4 class="pa-2">
                            <v-text-field label="BIC" v-model="form.ctt_customfields['ctt_bnk_bic']"></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Adresse</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-layout row wrap>
                        <v-flex xs4 class="pa-2">
                            <v-text-field label="Addresse 1" v-model="form.ctt_customfields['ctt_add_adr1']"></v-text-field>
                        </v-flex>
                        <v-flex xs4 class="pa-2">
                            <v-text-field label="Addresse 2" v-model="form.ctt_customfields['ctt_add_adr2']"></v-text-field>
                        </v-flex>
                        <v-flex xs4 class="pa-2">
                            <v-text-field label="Addresse 3" v-model="form.ctt_customfields['ctt_add_adr3']"></v-text-field>
                        </v-flex>
                        <v-flex xs4 class="pa-2">
                            <v-text-field label="Code postale" v-model="form.ctt_customfields['ctt_add_zip']"></v-text-field>
                        </v-flex>
                        <v-flex xs4 class="pa-2">
                            <v-text-field label="Ville" v-model="form.ctt_customfields['ctt_add_town']"></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        </v-layout>
    </v-card>
    <div class="text-right mt-3 mr-3">
        <v-btn class="mr-2" color="success" :loading="saveProgress" @click.native="saveForm()" :disabled="saveProgress">
        Enregistrer
        </v-btn>
        <v-btn color="primmary" href="/#/contacts/">
        Retour
        </v-btn>
    </div>
<!--    <v-data-table class="mt-4 ml-2 mr-2 elevation-2" v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" :items-per-page="pagination.rowsPerPage" :footer-props="footer">
        <template v-slot:item.trs_typ_id="{ item }">
            <v-chip small outlined label>{{ getCacheType('SIT', item.trs_typ_id).typ_name }}</v-chip>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
-->
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'user_card',
    props: ['toast'],
    data: function () {
        return {
            search: '',
            footer: { 'items-per-page-all-text':"tout", 'items-per-page-text':'Ligne par page' },
            pagination: { totalItems: 0, rowsPerPage: 5, rowsPerItem: [5,15,25,35,{text:'Tout',value:-1}] },
            saveProgress: false,         // Enregistrement en cours...
            form:{
              ctt_id:'', ctt_sta_id: '', ctt_typ_id: '',
              ctt_firstname: '', ctt_lastname: '', ctt_middlename: '',
              ctt_phone: '', ctt_mobile: '', ctt_fax: '', ctt_adr_id: '',
              ctt_email: '', ctt_company_name: '', ctt_customfields: {}
            },
            locDateFormat: dateLocale, // Format de date
            items: [],
            headers: [
            { text: 'Code', align: 'left', value: 'trs_mc2_code'},
            { text: 'Nom', align: 'left', value: 'trs_name'},
            { text: 'Type', value: 'trs_typ_id' },
            { text: 'B.V.', align: 'left', value: 'trs_id' },
            { text: 'Surface', align: 'left', value: 'trs_id' }
            ],
        }
    },
    computed: {

    },
    mounted:function (){
        if( parseInt( this.$route.params.ctt_id ) > 0 && this.$route.params.ctt_id !== undefined ){
            this.$http.get( '/contacts/' + this.$route.params.ctt_id )
            .then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( this.form[prop] !== undefined ){
                        this.form[prop] = response.data.data[prop] 
                    }
                }
                this.form.ctt_customfields = (!this.form.ctt_customfields) ? {} : this.form.ctt_customfields
            })
            .catch( (error) => {
                this.toast({ color: 'warning', text: error })
            })
            this.$http.get( '/contacts/' + this.$route.params.ctt_id + '/trackingsheets/' )
            .then( (response) => {
                this.items = response.data
            })
        }
    },
    methods: {
        saveForm () {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.$route.params.ctt_id > 0 ){
                this.$http.put( '/contacts/' + this.$route.params.ctt_id, this.form )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/contacts' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                });
            } else {
                this.form.ctt_middlename = this.form.ctt_firstname + ' ' + this.form.ctt_lastname
                this.$http.post( '/contacts/', this.form )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/contacts' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            }
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: { }
}
</script>