export const scopeCredential = {

        dashboard: {
            read:        ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'],
            edit:        ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            lastsheet:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            lastevent:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        },
        event     : {},
        eventedit : {
            read:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'],
            edit:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            delete: ['ADMIN_USER', 'SUPER_USER']
        },
        trackingsheet     : {},
        trackingsheetedit : {
            read:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'],
            edit:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            delete: ['ADMIN_USER', 'SUPER_USER']
        },
        map : {},
        document : {
            read:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'],
            edit:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            delete: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER']
        },
        report : {},
        reportext : {},
        // Params
        sitesedit: {},
        users: {
            read: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER']
        },
        useredit: {
            read: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            edit: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER']
        },
        states: {},
        stateedit: {
            read: ['ADMIN_USER', 'SUPER_USER'],
            edit: ['ADMIN_USER', 'SUPER_USER']
        },
        types: {},
        typeedit: {
            read: ['ADMIN_USER', 'SUPER_USER'],
            edit: ['ADMIN_USER', 'SUPER_USER']
        },
        contacts: {
            read:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'],
            edit:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            delete: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER']
        },
        contacts_view: {
            read:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'],
            edit:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            delete: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER']
        },

}