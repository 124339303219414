import { scopeCredential } from "./scopeCredential"

const ChMain      = () => import(/* webpackChunkName: "build-glob" */ './layouts/Main.vue') 
const ChDasboard  = () => import(/* webpackChunkName: "build-glob" */ './pages/Dashboard.vue')
const ChDasboardAlert  = () => import(/* webpackChunkName: "build-glob" */ './pages/Dashboard_alert.vue')
const ChListPre   = () => import(/* webpackChunkName: "build-glob" */ './pages/Preventive.vue')
const ChListCur   = () => import(/* webpackChunkName: "build-glob" */ './pages/Curative.vue')
//const ChMap       = () => import(/* webpackChunkName: "build-map" */ './pages/Map.vue')
const ChDocument  = () => import(/* webpackChunkName: "build-doc" */ './pages/Document.vue')
const ChSlider    = () => import(/* webpackChunkName: "build-glob" */ './components/Slider.vue')
const ChViewTrs   = () => import(/* webpackChunkName: "build-glob" */ './components/View_trackingSheet.vue')
const ChPreViewTrs= () => import(/* webpackChunkName: "build-glob" */ './components/View_preview_sheet.vue')

export default {
menu : [
    { 'href': '/dashboard',     'title': 'Tableau de bord', 'icon': 'mdi-view-dashboard', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'] },
    { 'href': '/preventive',    'title': 'Préventif', 'icon': 'mdi-calendar-check', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'] },
    { 'href': '/curative',      'title': 'Curatif', 'icon': 'mdi-format-list-checks', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'] },
    { 'href': '/dashboardalert','title': 'Synthèse alertes', 'icon': 'mdi-alert', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'] },
//    { 'href': '/sheets',        'title': 'Fiche intervention', 'icon': 'mdi-text-box-multiple-outline', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'] },
    { 'href': '/planning',      'title': 'Planning entretient', 'icon': 'mdi-calendar-multiselect', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'] },
    { 'href': '/myplanning',    'title': 'Mon planning', 'icon': 'mdi-calendar-multiselect', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'] },
    { 'href': '/documents',     'title': 'Documents', 'icon': 'mdi-briefcase', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'] },
    { divider: true },
    { 'header': 'Reporting', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'] },
    { 'href': '/reports', 'title': 'Export', 'icon': 'mdi-file-chart', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'] },
    { divider: true },
    { 'header': 'Paramètres',      'title': 'Paramètres', 'icon': 'mdi-cogs', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'PARTE_USER'], items: [
        { 'href': '/states',       'title': 'Gestion états', 'icon': 'mdi-tag-multiple', 'role': ['ADMIN_USER'] },
        { 'href': '/types',        'title': 'Gestion des types', 'icon': 'mdi-format-list-bulleted-type', 'role': ['ADMIN_USER'] },
        { 'href': '/contacts',     'title': 'Gestion des contacts', 'icon': 'mdi-account-box-multiple', 'role': scopeCredential.contacts.read },
        { 'href': '/provider',     'title': 'Prestataires', 'icon': 'mdi-account-box-multiple', 'role': scopeCredential.contacts.read },
        { 'href': '/equipment',    'title': 'Materiel', 'icon': 'mdi-format-list-bulleted-type', 'role': ['ADMIN_USER'] },
    ] },
    { divider: true },
    { 'header': 'Systeme', 'role': ['ADMIN_USER', 'SUPER_USER' ] },
    { 'href': '/users', 'title': 'Utilisateurs', 'icon': 'mdi-account', 'role': ['ADMIN_USER', 'SUPER_USER' ] },
],
routes : [
    { name:'login', path: '/login',   component: require('./layouts/Login.vue').default, 
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    { name:'loginsso', path: '/login/:authcode',   component: require('./layouts/Login.vue').default, 
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    { name:'logout', path: '/logout',   component: require('./layouts/Logout.vue').default },
    { name: '404', path: '*', component: require('./layouts/404.vue').default },
    { name: '401', path: '/401', component: require('./layouts/401.vue').default },
    { path: '/', component: ChMain,
        children: [
            { name: 'home', path: '/', redirect: '/dashboard' },
            { name: 'dashboard', path: '/dashboard', 
                components: { default: ChDasboard, modal: ChSlider },
                children: [
                  { name: 'dash_slide_prev', path: 'sheet/:trs_id', meta: { component: 'siteView' }, components: { content: ChPreViewTrs } },
                  { name: 'dash_slide_edit', path: 'sheet/:trs_id/modifier', components: { content: ChViewTrs } },
                  { name: 'dash_slide_view', path: 'sheet/:trs_id/consulter', components: { content: ChViewTrs } }
                ]
            },
            { name: 'dashboardalert', path: '/dashboardalert', 
                components: { default: ChDasboardAlert, modal: ChSlider },
                children: [
                  { name: 'daal_slide_prev', path: 'sheet/:trs_id', meta: { component: 'siteView' }, components: { content: ChPreViewTrs } },
                  { name: 'daal_slide_edit', path: 'sheet/:trs_id/modifier', components: { content: ChViewTrs } },
                  { name: 'daal_slide_view', path: 'sheet/:trs_id/consulter', components: { content: ChViewTrs } }
                ]
            },
            { name: 'preventive', path: '/preventive', 
                components: { default: ChListPre, modal: ChSlider },
                children: [
                  { name: 'pre_slide_prev', path: 'sheet/:trs_id', meta: { component: 'siteView' }, components: { content: ChPreViewTrs } },
                  { name: 'pre_slide_edit', path: 'sheet/:trs_id/modifier', components: { content: ChViewTrs } },
                  { name: 'pre_slide_view', path: 'sheet/:trs_id/consulter', components: { content: ChViewTrs } }
                ]
            },
            { name: 'curative', path: '/curative', 
                components: { default: ChListCur, modal: ChSlider },
                children: [
                  { name: 'cur_slide_prev', path: 'sheet/:trs_id', meta: { component: 'siteView' }, components: { content: ChPreViewTrs } },
                  { name: 'cur_slide_edit', path: 'sheet/:trs_id/modifier', components: { content: ChViewTrs } },
                  { name: 'cur_slide_view', path: 'sheet/:trs_id/consulter', components: { content: ChViewTrs } }
                ]
            },
/*            { name: 'map', path: '/map', 
                components: { default: ChMap, modal: ChSlider },
                children: [
                  { name: 'map_slide_prev', path: 'sheet/:trs_id', meta: { component: 'siteView' }, components: { content: ChPreViewTrs } },
                  { name: 'map_slide_edit', path: 'sheet/:trs_id/modifier', components: { content: ChViewTrs } },
                  { name: 'map_slide_view', path: 'sheet/:trs_id/consulter', components: { content: ChViewTrs } }
                ]
            },*/
            { name: 'document', path: '/documents', 
                components: { default: ChDocument, modal: ChSlider },
                children: [
                  { name: 'doc_slide_prev', path: 'sheet/:trs_id', meta: { component: 'siteView' }, components: { content: ChPreViewTrs } },
                  { name: 'doc_slide_edit', path: 'sheet/:trs_id/modifier', components: { content: ChViewTrs } },
                  { name: 'doc_slide_view', path: 'sheet/:trs_id/consulter', components: { content: ChViewTrs } }
                ]
            },
            { name: 'planning',      path: '/planning', component: require('./pages/Planning.vue').default },
            { name: 'myplanning',    path: '/myplanning', component: require('./pages/Myplanning.vue').default },
            { name: 'report',        path: '/reports', component: require('./pages/Report.vue').default },
            
            { name: 'users',         path: '/users', component: require('./pages/Users.vue').default },
            { name: 'useredit',      path: '/users/:usr_id',  component: require('./pages/User_edit.vue').default },
            { name: 'states',        path: '/states', component: require('./pages/States.vue').default },
            { name: 'statesedit',    path: '/states/:sta_id',  component: require('./pages/States_edit.vue').default },
            { name: 'types',         path: '/types', component: require('./pages/Types.vue').default },
            { name: 'typesedit',     path: '/types/:typ_id', component: require('./pages/Type_edit.vue').default },
            { name: 'contacts',      path: '/contacts', component: require('./pages/Contacts.vue').default },
            { name: 'contacts_view', path: '/contacts/:ctt_id', component: require('./pages/Contacts_edit.vue').default },
            { name: 'provider',      path: '/provider', component: require('./pages/Contacts.vue').default },
            { name: 'equipment',      path: '/equipment', component: require('./pages/Equipment.vue').default },
            { name: 'equipmentedit',      path: '/equipment/:equ_id', component: require('./pages/Equipment_edit.vue').default },

            { name: 'forbidden',    path: '/forbidden', component: require('./layouts/403.vue').default },
            
        ]
    }
]


}