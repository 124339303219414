<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-1" height="48px">
        <v-toolbar-title >Gestion du materiel</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="success" depressed class="white--text" to="/equipment/0">
            <v-icon left dark>mdi-plus-circle</v-icon>
            <span class="hidden-sm-and-down">Créer</span>
        </v-btn>
    </v-toolbar>
    <v-text-field
        append-icon="mdi-magnify" label="Recherche"
        solo clearable single-line hide-details v-model="search" @blur="saveSearchToCache()" class="ma-4 pa-0">
    </v-text-field>
    <v-card class="ma-4">
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:[`item.equ_name`]="{ item }">
            {{ item.equ_name }}
        </template>
        <template v-slot:[`item.equ_sta_id`]="{ item }">
            <v-chip small outlined label>{{ equStatus[item.equ_sta_id] }}</v-chip>
        </template>
        <template v-slot:[`item.equ_cat_id`]="{ item }">
            <v-chip small outlined label>{{ equCat[item.equ_cat_id] }}</v-chip>
        </template>
        <template v-slot:[`item.equ_typ_id`]="{ item }">
            <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/equipment/' + item.equ_id + ''">
                <v-icon left class="mr-2">mdi-pencil</v-icon>Modifier
            </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters, cacheMutations } from "../store/cache.module"

export default {
    name: 'species',
    props: ['toast'],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Id',                  align: 'left', value: 'equ_id'},
            { text: 'Nom',                 align: 'left', value: 'equ_name'},
            { text: 'Group',               align: 'left', value: 'equ_uid'},
            { text: 'Type',                align: 'left', value: 'equ_sta_id'},
            { text: 'Catégorie',           align: 'left', value: 'equ_cat_id'},
            { text: 'Modification',        align: 'right',value: 'equ_typ_id' }
            ],
            items: [],
            locDateFormat: dateLocale,
            enableAdd: true,
            modalEvent : false,
            cacheTab: this.$storage.localStorage.get( this.$APP_COD + "_cache" ),
            equStatus: {
                'GEN01' : 'Actif',
                'GEN02' : 'Inactif',
                'GEN03' : 'Terminé',
                'GEN04' : 'Archivé',
                'GEN05' : 'Supprimé',
            },
            equCat: {
                 1  : 'VEHICULES - VOITURE' ,
                 2  : 'VEHICULES - FOURGON' ,
                 3  : 'VEHICULES - CAMION BENNE' ,
                 4  : 'TONDEUSE AUTO - Z147' ,
                 5  : 'TONDEUSE AUTO - TORO' ,
                 6  : 'TONDEUSE AUTO - KUBOTA' ,
                 7  : 'PETITS MATERIELS - TONDEUSE' ,
                 8  : 'PETITS MATERIELS - DEBROUS' ,
                 9  : 'PETITS MATERIELS - SOUFFLEUR' ,
                 10 : 'PETITS MATERIELS - TAILLE HAIE' ,
                 11 : 'PETITS MATERIELS - ASPIRATEUR' ,
                 12 : 'PETITS MATERIELS - TRONCONNEUSE' ,
                 13 : 'DIVERS - TONDEUSE 4000 D' ,
                 14 : 'DIVERS - COUP ECO' ,
                 15 : 'DIVERS - TRACTEUR KUBOTA' ,
                 16 : 'DIVERS - MULE KAWA' ,
                 17 : 'DIVERS - BROYEUR BRANCHES' ,
            },
        }
    },
    computed: {
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        }
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('species') || ''
        this.getItems()
    },
    methods: {
        getItems(){
            this.$http.get( '/equipment?per_page=false' ).then( (response) => {
                this.items = response.data.data
            })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
        },
        getTypeName( typ_id ){
            if( this.cacheTab.types['SPE'] !== undefined && this.cacheTab.types['SPE'][typ_id] !== undefined ){
                return this.cacheTab.types['SPE'][typ_id].typ_name 
            } else {
                return 'non définit'
            }
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('species', this.search)
        },
    },
    components: {
        
    }
}
</script>