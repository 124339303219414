<template>
<v-container fluid>
<v-layout wrap>
    <v-flex xs6 style="max-width:100%">
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="green" >
                <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Export de l'activité</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon>mdi-download</v-icon>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-flex xs12>
                    <v-menu ref="datebyconvention" v-model="dateMenu.date_byconvention"
                        :return-value.sync="dateReport.date_byconvention"
                        :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                        offset-y min-width="290px" style="z-index:1500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-bind="attrs" :value="locDateFormat.formatLocale( dateReport.date_byconvention, 'fr' )"
                                label="Date de référence de l'extraction" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                            </v-text-field>
                        </template>
                        <v-date-picker locale="fr-fr" no-title scrollable v-model="dateReport.date_byconvention">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="dateMenu.date_byconvention = false">Annuler</v-btn>
                            <v-btn color="primary" @click="$refs.datebyconvention.save( dateReport.date_byconvention )">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-flex>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" :disabled="loading.getItem">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
    <v-flex xs6 style="max-width:100%">
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="primary" >
                <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Export matériel</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon>mdi-download</v-icon>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-flex xs12>
                    
                </v-flex>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" :disabled="loading.getItem">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
</v-layout>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"
import { trsObj }  from '../mixins/trs_obj.js'

export default {
    name: 'site_report',
    props: ['toast'], 
    mixins: [ trsObj ],
    data: function () {
        return {
            locDateFormat: dateLocale, // Format de date
            loading: { getItem: false },
            dateMenu: { 
                date_byconvention   : null,
                date_byspecification: null,
                date_event_start    : null,
                date_event_end      : null,
                date_inv_start    : null,
                date_inv_end      : null,
            },
            dateReport : {
                date_byconvention   : null,
                date_byspecification: null,
                date_event_start    : null,
                date_event_end      : null,
                date_inv_start    : null,
                date_inv_end      : null,
            },
            items: [
                { name: 'Identification',  id: 'trs_id' }, 
                { name: 'Code mc2',        id: 'trs_mc2_code' }, 
                { name: 'Coddt' ,          id: 'trs_coddt' }, 
                { name: 'Nom du site' ,    id: 'trs_name' }, 
                { name: 'Ville',           id: 'tow_name'}, 
                { name: 'Surface validée', id: 'trs_area_specification' },
                { name: 'Distance ligne',  id: 'trs_rail_distance' },
            ],
            select: [
                { name: 'Identification',  id: 'trs_id' }, 
                { name: 'Coddt' ,          id: 'trs_coddt' }, 
                { name: 'Surface validée', id: 'trs_area_specification' },
                { name: 'Distance ligne',  id: 'trs_rail_distance' },
            ],
            state : cacheGetters.getTypesByTag('EVE', 'PRO'),
        }
    },
    mounted:function (){
       
    },
    methods: {
        downloadReport(report, option){
            this.loading.getItem = true
            let searchCriteria = this.getTrackingSheetFilter()
            for(let prop in option){
                if( option[prop] !== null && option[prop] !== undefined ){
                    searchCriteria += '&' + prop + '=' + option[prop]
                }
            }
            this.$http.customRequest({ method: 'get', url: '/report/' + report + '/' + searchCriteria, responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, "rapport.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                this.loading.getItem = false
            }).catch( (error) => {
                this.toast({ text: 'Erreur lors de l\'extraction !', error: error })
                this.loading.getItem = false
            })
        },
        downloadSite(){
            this.loading.getItem = true
            let searchCriteria = this.getTrackingSheetFilter()
            this.$http.customRequest({ method: 'get', url: '/report/trackingsheet/' + searchCriteria, responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, "rapport.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                this.loading.getItem = false
            }).catch( (error) => {
                this.toast({ text: 'Erreur lors de l\'extraction !', error: error })
                this.loading.getItem = false
            })
        },
    },
    components: { }
}
</script>