<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-2" height="48px">
        <v-btn icon href="/#/equipment/"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-toolbar-title >Materiel</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip outlined color="blue" v-if="form.spe_updated_at">
            <v-icon left>mdi-information-outline</v-icon>Modification: {{ locDateFormat.formatLocale(form.spe_updated_at, 'fr') }}
        </v-chip>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Nom"     v-model="form.equ_name" ></v-text-field>
        </v-flex>
        <v-flex xs12 class="pa-2">
            <v-text-field ref="scanZone" @focus="scannfc()" :label="scanLabel"  v-model="form.equ_uid" ></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select 
                :items="categorieList" item-value="value" v-model="form.equ_cat_id" label="Catégorie" >
            </v-select>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select 
                :items="statusList" item-value="value" v-model="form.equ_sta_id" label="Statut" single-line >
            </v-select>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Modele"  v-model="form.equ_customfields.model" ></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Immatriculation"  v-model="form.equ_customfields.immat" ></v-text-field>
        </v-flex>
        </v-layout>
    </v-card>
    <v-toolbar extended flat color="transparent" class="elevation-0" >
        <v-btn color="red" @click.native="deleteItem()"><v-icon left>mdi-delete</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" class="mr-3" :loading="saveProgress" @click.native="saveItem()" :disabled="!validate">Enregistrer</v-btn>
        <v-btn color="primmary" href="/#/equipment/">Retour</v-btn>
    </v-toolbar>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"
//import autoCompMulti    from '../components/Autocomplete_multi.vue'

export default {
    name: 'equipmentedit',
    props: ['toast'],
    data: function () {
        return {
            locDateFormat: dateLocale, // Format de date
            saveProgress: false,         // Enregistrement en cours...
            scanLabel: 'cliquez pour scanner',
            loading: { getItem: true },
            typeSpe : cacheGetters.getTypes('SPE'),
            form:{ 
                equ_id : '', equ_uid : '', equ_sta_id : 'GEN01', equ_typ_id : 'TYP01', equ_name : '', equ_cat_id : 1, equ_customfields : {},
            },
            autoComplete: {
                spe_spg_id: null,
            },
            autoCompleteSource:{
                spe: cacheGetters.getSpeciesGroup(),
            },
            statusList: [
                {'value': 'GEN01',    'text': 'Actif'},
                {'value': 'GEN02',    'text': 'Inactif'},
                {'value': 'GEN03',    'text': 'Terminé'},
                {'value': 'GEN04',    'text': 'Archivé'},
                {'value': 'GEN05',    'text': 'Supprimé'},
            ],
            categorieList: [
                {'value': 1,  'text': 'VEHICULES - VOITURE' },
                {'value': 2,  'text': 'VEHICULES - FOURGON' },
                {'value': 3,  'text': 'VEHICULES - CAMION BENNE' },
                {'value': 4,  'text': 'TONDEUSE AUTO - Z147' },
                {'value': 5,  'text': 'TONDEUSE AUTO - TORO' },
                {'value': 6,  'text': 'TONDEUSE AUTO - KUBOTA' },
                {'value': 7,  'text': 'PETITS MATERIELS - TONDEUSE' },
                {'value': 8,  'text': 'PETITS MATERIELS - DEBROUS' },
                {'value': 9,  'text': 'PETITS MATERIELS - SOUFFLEUR' },
                {'value': 10, 'text': 'PETITS MATERIELS - TAILLE HAIE' },
                {'value': 11, 'text': 'PETITS MATERIELS - ASPIRATEUR' },
                {'value': 12, 'text': 'PETITS MATERIELS - TRONCONNEUSE' },
                {'value': 13, 'text': 'DIVERS - TONDEUSE 4000 D' },
                {'value': 14, 'text': 'DIVERS - COUP ECO' },
                {'value': 15, 'text': 'DIVERS - TRACTEUR KUBOTA' },
                {'value': 16, 'text': 'DIVERS - MULE KAWA' },
                {'value': 17, 'text': 'DIVERS - BROYEUR BRANCHES' },
            ],
            equStatus: {
                'GEN01' : 'Actif',
                'GEN02' : 'Inactif',
                'GEN03' : 'Terminé',
                'GEN04' : 'Archivé',
                'GEN05' : 'Supprimé',
            },
        }
    },
    computed: {
        validate () {
            if( !this.saveProgress && this.form.equ_name.length > 0 ){
                return true
            } else {
                return false
            }
            
        },
    },
    mounted:function (){
        if ('NDEFReader' in window) {
            this.scanLabel = 'cliquez pour scanner'
        } else {
            this.scanLabel = 'Votre appareil ne peut pas lire du NFC'
        }
        if( parseInt( this.$route.params.equ_id ) > 0 && this.$route.params.equ_id !== undefined ){
            this.getItem()
        } else {
            this.loading.getItem = false
        }
    },
    methods: {
        getItem() {
            this.$http.get( '/equipment/' + this.$route.params.equ_id )
            .then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( this.form[prop] !== undefined ){
                        this.form[prop] = response.data.data[prop] 
                    }
                }
                this.form.equ_customfields = (!this.form.equ_customfields) ? {} : this.form.equ_customfields
                this.loading.getItem = false
            })
            .catch( (error) => {
                this.toast({ color: 'warning', text: error })
            })
        },
        saveItem () {
            // Enregistrement du formulaire
            this.saveProgress    = true
            this.form.spe_spg_id = this.autoComplete.spe_spg_id
            if( parseInt( this.$route.params.equ_id ) > 0 ){
                this.$http.put( '/equipment/' + this.$route.params.equ_id, this.form )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/equipment' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            } else {
                this.$http.post( '/equipment/', this.form )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/equipment' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                });
            }
        },
        deleteItem(){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression de cette fiche', { color: 'red', width: 500 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( '/equipment/' + this.form.equ_id ).then( () => {
                        this.toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée !' })
                        this.$router.push({ path: '/equipment' })
                    })
                }
            })
        },
        async scannfc(){
            /*global NDEFReader*/
            try {
                this.scanLabel = 'Scannez la puce'
                const ndef = new NDEFReader()
                await ndef.scan()

                ndef.addEventListener("readingerror", () => {
                    this.scanLabel = "Impossible de lire la puce"
                })

                ndef.addEventListener("reading", ({ message, serialNumber }) => {
                    this.form.equ_uid = serialNumber
                    console.log(message)
                    //log(`> Records: (${message.records.length})`);
                });
            } catch (error) {
                this.scanLabel = "Argh! " + error
            }            
        }
    },
    components: {
        //'auto-multi': autoCompMulti
    }
}
</script>